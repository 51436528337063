<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogMergeProposals" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
      :header="'Merge Propostas'"
      :modal="true"
      :closable="false"
    >
      <Loading
        :active.sync="showLoader"
        :isFullPage="false"
        :zIndex="9000"
      ></Loading>

      <DataTable
        :value="selected"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :selection.sync="master"
        :sortOrder="1"
        sortField="id"
      >
        <template #header>
          <div class="table-header">
            Selecione a Proposta que irá receber as restantes propostas da
            listagem em baixo.
          </div>
        </template>
        <Column selectionMode="single" :headerStyle="{ width: '3em' }"></Column>
        <Column field="id" header="Id"></Column>
        <Column field="client_email" header="Cliente"></Column>
        <Column field="subject" header="Assunto"></Column>
        <Column field="proposal_status.name" header="Estado"></Column>
        <Column field="proposal_category.name" header="Categoria"></Column>
        <Column
          field="proposal_assigned_user.username"
          header="Resp. Orçamento"
        ></Column>
      </DataTable>

      <template #footer>
        <Button
          v-if="master"
          :label="'Merge'"
          icon="pi pi-check"
          class="p-button-success"
          @click="merge"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import proposalService from "../../services/proposal.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "MergeProposals",
  components: {
    Loading,
  },
  props: ["showToast", "show", "selected"],
  data() {
    return {
      showLoader: false,
      master: null,
    };
  },

  methods: {
    cancel() {
      this.master = null;
      return this.$emit("cancel");
    },
    merge() {
      let proposalsToImportIds = this.selected.filter((proposal) => {
        if (proposal.id != this.master.id) {
          return proposal.id;
        }
      });
      let proposalsToImport = proposalsToImportIds.map((ticket) => ticket.id);
      let message = `Tem a certeza que pretende importar as propostas ${proposalsToImport.join(
        ", "
      )} para a Proposta W4M_${this.master.id}?`;
      message += `\nEste processo é irreversível.`;

      this.$confirm.require({
        key: "dialogProposalTicket",
        header: "Merge Propostas",
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.importProposals(proposalsToImport);
        },
        reject: () => {},
      });
    },
    importProposals(proposalsToImport) {
      this.showLoader = true;
      return proposalService
        .importProposals(this.master.id, {
          proposals_import: proposalsToImport,
        })
        .then((response) => {
          this.master = null;
          this.showLoader = false;
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: "Erro ao fazer merge das propostas",
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("mergedProposals", {
              status: "error",
            });
          }

          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Merge das Propostas Efetuado com sucesso`,
              detail: ``,
              life: 3000,
            });
          }
          return this.$emit("mergedProposals", {
            status: "ok",
            destinationProposalId: response.id,
          });
        });
    },
  },
};
</script>
