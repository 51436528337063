<template>
  <div class="card p-shadow-6">
    <h1>Propostas</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <W4Mstreak
      :states="streakStatus"
      v-on:W4MStreakChangeSelected="streakSelectedChange"
    />
    <ChangeResponsible
      :showToast="true"
      :show="proposalChangeResponsible.show"
      :proposalId="proposalChangeResponsible.proposalId"
      :proposalSubject="proposalChangeResponsible.proposalSubject"
      :currentTechnician="proposalChangeResponsible.technicianId"
      :selected="proposalChangeResponsible.selected"
      v-on:cancel="changeResposibleCancel"
      v-on:changeTechnicianResp="changeResposibleOk"
    />
    <ChangeCategory
      :showToast="true"
      :show="proposalChangeCategory.show"
      :proposalId="proposalChangeCategory.proposalId"
      :proposalSubject="proposalChangeCategory.proposalSubject"
      :categoryId="proposalChangeCategory.categoryId"
      v-on:cancel="changeCategoryCancel"
      v-on:changedProposalCategory="changeCategoryOk"
    />
    <ChangePriority
      :showToast="true"
      :show="proposalChangePriority.show"
      :proposalId="proposalChangePriority.proposalId"
      :proposalSubject="proposalChangePriority.proposalSubject"
      :priorityId="proposalChangePriority.priorityId"
      :selected="proposalChangePriority.selected"
      v-on:cancel="changePriorityCancel"
      v-on:changedProposalPriority="changePriorityOk"
    />
    <ChangeStatus
      :showToast="true"
      :show="proposalChangeStatus.show"
      :proposalId="proposalChangeStatus.proposalId"
      :proposalSubject="proposalChangeStatus.proposalSubject"
      :statusId="proposalChangeStatus.statusId"
      :selected="proposalChangeStatus.selected"
      v-on:cancel="changeStatusCancel"
      v-on:changedProposalStatus="changeStatusOk"
    />
    <MergeProposals
      :showToast="true"
      :show="showMergeProposal"
      :selected="selectedProposals"
      v-on:cancel="showMergeProposal = false"
      v-on:mergedProposals="mergedProposalsOk"
    />
    <div style="height: 65vh">
      <DataTable
        :value="proposals"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="status"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        removableSort
        groupRowsBy="proposal_status.name"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :selection.sync="selectedProposals"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm p-mr-1"
              label="Mostrar todos as propostas"
              @click="showAllProposal()"
            />
            <Button
              v-if="selectedProposals && selectedProposals.length > 1"
              icon="pi pi-sitemap"
              class="p-button-sm p-mr-1"
              label="Merge Propostas"
              @click="showMergeProposal = true"
            />
            <span style="position: absolute; right: 10px">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="arquiveSearch"
                  type="text"
                  placeholder="Pesquisar"
                  class="p-inputtext-sm"
                  @blur="searchProposals"
                  @keyup.enter="searchProposals"
                />
              </span>
            </span>
          </div>
        </template>
        <Column
          selectionMode="multiple"
          :headerStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :filterHeaderStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :bodyStyle="{ 'min-width': '3%', 'max-width': '3%' }"
        ></Column>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ slotProps.data.proposal_status.name }}
          </div>
        </template>
        <Column
          sortable
          field="id"
          header="Id"
          filterField="id"
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Proposta'"
              @click="viewProposal(slotProps.data.id)"
              >{{ slotProps.data.id }}</span
            >
            <i
              class="pi pi-inbox p-ml-2"
              v-tooltip="'Arquivado'"
              v-if="slotProps.data.archived === true"
            ></i>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="client_name"
          header="Cliente"
          filterField="client_name"
          :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.client_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="subject"
          header="Assunto"
          filterField="subject"
          :headerStyle="{ 'min-width': '19%', 'max-width': '19%' }"
          :filterHeaderStyle="{ 'min-width': '19%', 'max-width': '19%' }"
          :bodyStyle="{ 'min-width': '19%', 'max-width': '19%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.subject }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="status"
          header="Estado"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor(slotProps.data.proposal_status.name)"
            ></i>
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="changeStatus(slotProps.data)"
            >
              {{ slotProps.data.proposal_status.name }}
            </span>
            <span v-else>
              {{ slotProps.data.proposal_status.name }}
            </span>
          </template>
        </Column>
        <Column
          sortable
          field="type_name"
          header="Tipo"
          filterField="type_name"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <span
              v-if="slotProps.data.type_id == 1 && slotProps.data.pat_id"
              class="clickable-item"
              v-tooltip="'Ver Survey'"
              @click="goToSurvey(slotProps.data.pat_id)"
            >
              {{ slotProps.data.type_name }}
            </span>
            <span v-else>
              {{ slotProps.data.type_name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="priority"
          header="Prioridade"
          filterField="priority"
          :headerStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :filterHeaderStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :bodyStyle="{ 'min-width': '7%', 'max-width': '7%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Prioridade'"
              @click="changePriority(slotProps.data)"
            >
              {{ slotProps.data.proposal_priority.name }}
            </span>
            <span v-else>
              {{ slotProps.data.proposal_priority.name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="priorityList"
              optionValue="priority"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          sortable
          field="seller_name"
          header="Vendedor"
          filterField="seller_name"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.seller_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="proposal_assigned_user.username"
          header="R. Orçamento"
          filterField="assignedTo"
          :headerStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :filterHeaderStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :bodyStyle="{ 'min-width': '9%', 'max-width': '9%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Técnico Responsável Pelo Orçamento'"
              @click="changeResposible(slotProps.data)"
              >{{
                !slotProps.data.assigned_to
                  ? "_______"
                  : slotProps.data.proposal_assigned_user.username
              }}</span
            >
            <span v-else>{{
              slotProps.data.assigned_to
                ? slotProps.data.proposal_assigned_user.username
                : ""
            }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="created_at"
          header="Criado"
          filterField="created_at"
          dataType="date"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            <span>{{ getDateTime(slotProps.data.created_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          sortable
          field="updated_at"
          header="Atualizado"
          filterField="updated_at"
          dataType="date"
          :headerStyle="{ 'min-width': '8.5', 'max-width': '8.5' }"
          :filterHeaderStyle="{ 'min-width': '8.5', 'max-width': '8.5' }"
          :bodyStyle="{ 'min-width': '8.5', 'max-width': '8.5' }"
        >
          <template #body="slotProps">
            <span>{{ getDateTime(slotProps.data.updated_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import proposalService from "../services/proposal.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import ChangeResponsible from "../components/proposal/ChangeResponsible.vue";
import ChangeCategory from "../components/proposal/ChangeCategory.vue";
import ChangePriority from "../components/proposal/ChangePriority.vue";
import ChangeStatus from "../components/proposal/ChangeStatus.vue";
import MergeProposals from "../components/proposal/MergeProposals.vue";

export default {
  name: "Proposals",
  components: {
    W4Mstreak,
    ChangeResponsible,
    ChangeCategory,
    ChangePriority,
    ChangeStatus,
    MergeProposals,
  },
  data() {
    return {
      streakStatus: [],
      proposals: [],
      priorityList: [],
      categoryList: [],
      expandedRowGroups: [],
      loading: false,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        priority: { value: null, matchMode: FilterMatchMode.EQUALS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
        type_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        assignedTo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
        updated_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
      proposalChangeResponsible: {
        show: false,
        proposalId: null,
        technicianId: null,
        proposalSubject: null,
      },
      proposalChangeCategory: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        categoryId: null,
      },
      proposalChangePriority: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        priorityId: null,
      },
      proposalChangeStatus: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        statusId: null,
      },
      showAll: false,
      selectedProposals: null,
      showMergeProposal: false,
      currentState: {},
      arquiveSearch: null,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    this.priorityList = await proposalService.getPriorityList();
    this.categoryList = await proposalService.getCategoryList();
    this.categoryList.push({ id: null, name: "Sem Categoria" });
    if (this.$route.params.status) {
      this.filters.technician.value = this.loggedUser.username;
    }
    await this.initStreakStatus();
  },
  methods: {
    async initStreakStatus() {
      this.loading = true;
      let news = await proposalService.countByStatus(0);
      let awaitResponse = await proposalService.countByStatus(1);
      let pendent = await proposalService.countByStatus(2);
      let open = await proposalService.countByStatus(4);
      let resolved = await proposalService.countByStatus(3);
      let closed = await proposalService.countByStatus(5);
      let rejected = await proposalService.countByStatus(6);

      let activeStatus = this.$route.params.status
        ? this.$route.params.status
        : "";
      this.streakStatus = [
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Novo",
          counter: news,
          active: activeStatus === "Novo",
          data: [],
        },
        {
          backgroudColor: "#FFA100",
          textColor: "#000",
          title: "Aberto",
          counter: open,
          active: activeStatus === "Aberto",
        },
        {
          backgroudColor: "#5F78DA",
          textColor: "#FFF",
          title: "Aguarda Resposta",
          counter: awaitResponse,
          active: activeStatus === "Aguarda Resposta",
        },
        {
          backgroudColor: "#5F78DA",
          textColor: "#FFF",
          title: "Pendente",
          counter: pendent,
          active: activeStatus === "Pendente",
        },
        {
          backgroudColor: "#4455BD",
          textColor: "#FFF",
          title: "Resolvido",
          counter: resolved,
          active: activeStatus === "Resolvido",
        },
        {
          backgroudColor: "#008340",
          textColor: "#FFF",
          title: "Fechado",
          counter: closed,
          active: activeStatus === "Fechado",
        },
        {
          backgroudColor: "#ff0000",
          textColor: "#FFF",
          title: "Rejeitado",
          counter: rejected,
          active: activeStatus === "Rejeitado",
        },
      ];

      if (activeStatus != "") {
        let activeStatusIndex = this.getStreakStatusIndex(activeStatus);
        if (activeStatusIndex >= 0) {
          let streakInfo = {
            currentStateIndex: activeStatusIndex,
            currentState: activeStatus,
          };
          this.streakSelectedChange(streakInfo);
        }
      }
      return (this.loading = false);
    },
    getGroupheaderColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.proposal_status.name
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor(info) {
      let statusInfo = this.streakStatus.find((status) => status.title == info);
      return { color: statusInfo.backgroudColor };
    },
    rowExpand(event) {
      let streakInfo = {
        currentStateIndex: this.getStreakStatusIndex(event.data),
        currentState: event.data,
      };
      return this.streakSelectedChange(streakInfo);
    },
    async showAllProposal() {
      this.showAll = true;
      this.proposals = [];
      for (const [index, status] of this.streakStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange(
          {
            currentStateIndex: index,
            currentState: status.title,
          },
          true
        );
      }
    },
    getStreakStatusIndex(status) {
      return this.streakStatus.findIndex(
        (streakItem) => streakItem.title == status
      );
    },
    streakStatusChange(from, to) {
      let statusFrom = this.streakStatus.find((status) => status.title == from);
      if (statusFrom) {
        statusFrom.counter = --statusFrom.counter;
      }
      if (to == "") {
        return;
      }
      let statusTo = this.streakStatus.find((status) => status.title == to);
      if (statusTo) {
        statusTo.counter = ++statusTo.counter;
      }
    },
    removeByStatus(status) {
      this.proposals.forEach((proposal, index, object) => {
        if (proposal.proposal_status.name === status) {
          object.splice(index, 1);
        }
      });
    },
    streakSelectedChange(selected, notRemove) {
      // if (
      //   this.streakStatus[selected.currentStateIndex].loaded != undefined &&
      //   this.streakStatus[selected.currentStateIndex].loaded == true
      // ) {
      //   return;
      // }
      // this.streakStatus[selected.currentStateIndex].loaded = true;
      this.currentState = selected;
      this.currentState.notRemove = notRemove;
      this.selectedProposals = null;
      this.arquiveSearch = null;
      this.removeByStatus(selected.currentState);
      switch (selected.currentState) {
        case "Novo":
          this.getProposalsByStatus(selected.currentStateIndex, 0, notRemove);
          break;
        case "Aguarda Resposta":
          this.getProposalsByStatus(selected.currentStateIndex, 1, notRemove);
          break;
        case "Pendente":
          this.getProposalsByStatus(selected.currentStateIndex, 2, notRemove);
          break;
        case "Aberto":
          this.getProposalsByStatus(selected.currentStateIndex, 4, notRemove);
          break;
        case "Resolvido":
          this.getProposalsByStatus(selected.currentStateIndex, 3, notRemove);
          break;
        case "Fechado":
          this.getProposalsByStatus(selected.currentStateIndex, 5, notRemove);
          break;
        case "Rejeitado":
          this.getProposalsByStatus(selected.currentStateIndex, 6, notRemove);
          break;
        default:
          return;
      }
      return this.expandedRowGroups.push(selected.currentState);
    },
    async getProposalsByStatus(streakIndex, status, notRemove) {
      this.loading = true;
      let statusProposal = await proposalService.getByStatus(status);
      statusProposal = statusProposal.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        created_at: new Date(`${v.created_at}`),
        updated_at: new Date(`${v.updated_at}`),
        client_name: v.client ? v.client.name : null,
        seller_name: v.seller ? v.seller.name : null,
        type_name: v.type ? v.type.name : null,
      }));
      if (notRemove != undefined && notRemove == true) {
        this.proposals = this.proposals.concat(statusProposal);
      } else {
        this.showAll = false;
        this.proposals = statusProposal;
      }
      return (this.loading = false);
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    getInfoFromEmail(email) {
      if (!email) {
        return "";
      }
      let info = email.replace("@", " | ");
      info = info.replace(/[.,-/_]/g, " ");
      return info.substring(0, info.lastIndexOf(" "));
    },
    showStatusMenu(assist) {
      this.statusMenu.show = true;
      this.statusMenu.assist = assist;
    },
    closeStatusMenu() {
      this.statusMenu.show = false;
    },
    viewProposal(proposalId) {
      let route = this.$router.resolve({
        path: `/proposal-detail/${proposalId}`,
      });
      window.open(route.href, "_blank");
    },
    goToSurvey(patId) {
      let route = this.$router.resolve({
        path: `/pat-details/${patId}`,
      });
      window.open(route.href, "_blank");
    },
    changeResposible(proposal) {
      return (this.proposalChangeResponsible = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.subject,
        technicianId: proposal.assigned_to,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeResposibleCancel() {
      return (this.proposalChangeResponsible = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        technicianId: null,
      });
    },
    changeResposibleOk(response) {
      if (response.status == "ok") {
        if (this.proposalChangeResponsible.selected) {
          for (var proposal of this.selectedProposals) {
            proposal.assigned_to = response.result.technician_id;
            proposal.proposal_assigned_user = {
              userno: response.result.responsible_user_id,
              username: response.result.responsible_user,
              tecnico: response.result.technician_id,
            };
          }
        } else {
          this.proposalChangeResponsible.proposal.assigned_to =
            response.result.technician_id;
          this.proposalChangeResponsible.proposal.proposal_assigned_user = {
            userno: response.result.responsible_user_id,
            username: response.result.responsible_user,
            tecnico: response.result.technician_id,
          };
        }
      }
      return this.changeResposibleCancel();
    },
    changeCategory(proposal) {
      return (this.proposalChangeCategory = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.subject,
        categoryId: proposal.category,
        proposal: proposal,
      });
    },
    changeCategoryCancel() {
      return (this.proposalChangeCategory = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        categoryId: null,
      });
    },
    changeCategoryOk(response) {
      if (response.status == "ok") {
        this.proposalChangeCategory.proposal.category =
          response.result.category_id;
        this.proposalChangeCategory.proposal.proposal_category = {
          id: response.result.category_id,
          name: response.result.category_name,
        };
      }
      return this.changeCategoryCancel();
    },
    changePriority(proposal) {
      return (this.proposalChangePriority = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.subject,
        priorityId: proposal.priority,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changePriorityCancel() {
      return (this.proposalChangePriority = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        priorityId: null,
      });
    },
    changePriorityOk(response) {
      if (response.status == "ok") {
        if (this.proposalChangePriority.selected) {
          for (var proposal of this.selectedProposals) {
            proposal.priority = response.result.priority_id;
            proposal.proposal_priority = {
              priority: response.result.priority_id,
              name: response.result.priority_name,
            };
          }
        } else {
          this.proposalChangePriority.proposal.priority =
            response.result.priority_id;
          this.proposalChangePriority.proposal.proposal_priority = {
            priority: response.result.priority_id,
            name: response.result.priority_name,
          };
        }
      }
      return this.changePriorityCancel();
    },
    changeStatus(proposal) {
      return (this.proposalChangeStatus = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.subject,
        statusId: proposal.status,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeStatusCancel() {
      this.selectedProposals = null;
      return (this.proposalChangeStatus = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        statusId: null,
      });
    },
    changeStatusOk(response) {
      if (response.status == "ok") {
        if (this.proposalChangeStatus.selected) {
          for (var proposal of this.selectedProposals) {
            this.streakStatusChange(
              proposal.proposal_status.name,
              response.result.status_name
            );
            proposal.status = response.result.status_id;
            proposal.proposal_status = {
              status: response.result.status_id,
              name: response.result.status_name,
            };
            if (!this.showAll) {
              this.removeByStatus(response.result.status_name);
            }
          }
        } else {
          this.streakStatusChange(
            this.proposalChangeStatus.proposal.proposal_status.name,
            response.result.status_name
          );
          this.proposalChangeStatus.proposal.status = response.result.status_id;
          this.proposalChangeStatus.proposal.proposal_status = {
            status: response.result.status_id,
            name: response.result.status_name,
          };
          if (!this.showAll) {
            this.removeByStatus(response.result.status_name);
          }
        }
      }
      return this.changeStatusCancel();
    },
    async mergedProposalsOk(response) {
      if (response.status == "ok") {
        this.showMergeProposal = false;
        await this.initStreakStatus();
        this.streakSelectedChange(
          this.currentState,
          this.currentState.notRemove
        );
      } else {
        this.showMergeProposal = false;
      }
    },
    async searchProposals() {
      if (this.arquiveSearch == null || this.arquiveSearch == "") {
        return;
      }

      this.loading = true;
      await proposalService.search(this.arquiveSearch).then((response) => {
        if (!response || response.length == 0) {
          return this.$toast.add({
            severity: "info",
            summary: `Resultado da Pesquisa`,
            detail: `Não existem propostas com o termo ${this.arquiveSearch}`,
            life: 3000,
          });
        }

        this.proposals = response.map((v) => ({
          ...v,
          created_at: new Date(`${v.created_at}`),
          updated_at: new Date(`${v.updated_at}`),
          client_name: v.client ? v.client.name : null,
          seller_name: v.seller ? v.seller.name : null,
          type_name: v.type ? v.type.name : null,
        }));
      });
      this.expandedRowGroups = this.proposals.reduce(function (acc, curr) {
        if (!acc.includes(curr.proposal_status.name))
          acc.push(curr.proposal_status.name);
        return acc;
      }, []);
      return (this.loading = false);
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}

tr.p-highlight {
  background: #bcbcbc !important;
}
</style>
